export const LOGIN = '/accounts/login/';
export const TOKEN_VALIDITY = '/accounts/token-check/';
export const AUTH_ME = '/accounts/auth/me/';
export const ALL_PHARMACY_LIST = "/pharmacy/pharmacy";
export const PHARMACY_URL = "/pharmacy/api/pharmacies/";
export const PHARMACY_detail_URL = "/pharmacy/api/pharmacydetailmdList/";
export const PHARMACY_employee_UR = "/pharmacy/api/pharmacyEmployeeList/";
export const PHARMACY_api_URL = "/pharmacy/pharmacydoctypemdList";
export const PHARMACY_apiupdate_URL = "/pharmacy/pharmacyupdate_mdList";
export const PHARMACY_bank_URL = "/pharmacy/BankInfodoctypemdList";
export const PHARMACY_bankupdate_URL = "/pharmacy/Bankupdate_mdList";
export const PHARMACY_doc_URL = "/pharmacy/DetailInfodoctypemdList";
export const PHARMACY_docupdate_URL = "/pharmacy/Detailupdate_mdList";
export const PHARAMCY_USER = "/accounts/users-pharmacy/"
export const ALL_ORDERS = "/ship/status/";
export const ORDER_COMMUNICATION = "/ship/order-communication/";
export const FCM_TOKEN = "/accounts/save-fcm-token/";
export const USER_PERMISSIONS = "/accounts/user-permissions/";
export const RESCHEDULE_ORDERS = "/ship/reorder/";
export const ORDER_DETAIL_LIST = "/ship/api/order-master-list/";
export const PERSON_ORDERS = "/ship/order-by-person/";
export const PHARMACY_ORDER_COUNT = "/ship/pharmacies/order-count/";
export const PHARMACY_REPORT_RANGE = "/ship/get/report/range";
export const PHARMACY_REPORT = "/ship/get/reports";
export const PHARMACY_REPORT_DETAIL = "/ship/get/reports/detail";
export const PHARMACY_REPORT_COUNT = "/ship/get/order/counts";
export const DRIVER_REPORT_COUNT = "/ship/get/driver/counts";
export const PHARMACY_REPORT_DOWNLOAD = "ship/download";
export const ORDER_Filter= "/ship/Orderstatusfilter";
export const ORDER_NOT_UPDATE= "/ship/OrderforUpdates";
export const CREATE_ORDER = '/ship/order';
export const MERGE_ORDER = '/ship/mergeorder';
export const GET_DASHBOARD = '/ship/orderdashboard'
export const MARK_READ= '/ship/mark-all-read';
export const MARK_READ_CANCEL= '/ship/mark-all-read-cancel';

export const SMS_CONFIG = '/ship/sms-config/'

export const URGENT_ORDER = '/ship/urgentOrders'
export const CANCEL_ORDER = '/ship/notficationOrder'
export const TWILIO_SMS_LIST = '/ship/twilio_sms/list/'
export const TWILIO_UNREAD_SMS_COUNT = '/ship/unread_twilio_sms/'
export const TWILIO_SMS_LIST_UNREAD = '/ship/twilio_sms/unread/list/'
export const CHANGE_ORDER_STATUS = '/ship/change/status/'
export const GET_HISTORY = '/ship/history/'
export const GET_ROUTE_HISTORY = '/api/route/history/'
export const MAKE_CALL = '/ship/make_call/'
export const CREATE_TICKET = '/ship/ordertickets/'
export const CREATE_DELIVERED_NOTE = 'ship/change/delivered/note/'
export const CREATE_PHARMACY_NOTE = 'ship/change/pharmacy/note/'
export const CREATE_ADMIN_NOTE = '/ship/change/note/'
export const CREATE_CALL_NOTE = '/ship/calls/'
export const CREATE_CANCEL_NOTE = '/ship/ordertickets/'
export const DRIVER_NOTE = '/ship/api/driver/'
export const ORDER_CHAT_LIST = '/ship/twilio/chat/list/'
export const ORDER_MISSED_CALLS = '/ship/twilio/missed/calls/'
export const UNKNOWN_CALL_ORDER_SET = '/ship/unknown-call/order-set/'
export const CREATE_COMMENT = '/ship/ticketcomments/'
export const ALL_ROUTES = "/api/routes/"
export const ALL_ROUTES_WEB = "/api/route_web/"
export const ROUTE_DETAIL = "/api/routeOrders/"
export const ALL_DRIVERS = "/vehicle/drivers"
export const ALL_DRIVERS_LIST = "/vehicle/driverslist"
export const DRIVERS_ROUTE = "/vehicle/driversroute"
export const ALL_DRIVERS_SCANNED = "/vehicle/driverscanned" 
export const ALL_DRIVERS_REPORTS = "/ship/get/driverreports/detail";
export const UPDATE_PROFILE_PIC = "/accounts/updatepropic";
export const ORDER_FAILED = "/ship/order-fail/"

export const DRIVERS_API = "/vehicle/api/drivers/"
// export const DRIVERS_API_2 = "/vehicle/driverdetailmdList"
export const DRIVERS_API_2 = "/vehicle/api/driverdetailmdList/"
export const vehicle_API_2 = "/vehicle/vehiclemdList"
export const Bank_API_2 = "/vehicle/driverBankmdList"
export const Doc_API_2 = "/vehicle/driverdoctypemdList"
export const VEHICLE_API = "/vehicle/"
export const ALL_USERS = "/accounts/"

export const USER_URL = "/accounts/user/"
export const USER_TYPE_URL = "/accounts/usertype/"
export const FACILITY_TEAM_URL = "/accounts/api/facilityteams/"
export const FACILITY_Team_york= "accounts/FacilityorktypemdList"
export const FACILITY_Team_bank = "accounts/FacilitybanktypemdList"
export const FacilityTeam_updatebank = "/accounts/FacilitybankupdatemdList"
export const FacilityTeam_updateyork = "/accounts/FacilityorkupdatemdList"
export const FACILITY_Team_api = "accounts/api/facility_team_add/"
export const FACILITY_Update_api = "accounts/facilityupdate_api"

export const FACILITY_Team_document = "accounts/FacilitydocumenttypemdList"
export const FACILITY_Team_document_update = "accounts/FacilitydocumentupdatemdList"
export const facility_data = '/accounts/FacilitydoctypemdList'
export const ALL_ZONES_LIST = "/cards/"
export const ADD_EXPENSE = "/cards/expenses/"
export const EXPENSE_LIST = "/cards/expense-list/"
export const getExpense = "/cards/expenses"

export const updateExpense = "/cards/expenses"
export const driver_update_api = '/vehicle/driver_update_api'
export const driver_bank_up_api = '/vehicle/DriverBankDetails'
export const driver_doc_up_api = '/vehicle/driverdocdelete'
export const driver_vehicle_up_api = '/vehicle/vehicleUpdateDeleteList'


export const ADD_DRIVER = "/vehicle/driverdocdelete";
export const ADD_BANK = '/vehicle/bankmdList'
export const ADD_VEHICLE = '/vehicle/vehiclemdList'
export const CREATE_ROUTE = '/api/routes/'
export const CREATE_ROUTE_ORDER = '/api/routeOrders/'
export const CREATE_ROUTE_RETURN_ORDER = '/api/routeReturnOrders/'
export const CREATE_ROUTE_ZONE = '/api/createRoute/zone/'
export const CREATE_ROUTE_RETURN_ZONE = '/api/createRoutereturn/zone/'
export const MERGE_ROUTE = '/api/merge/route/'
export const MERGE_ROUTE_RETURN = '/api/mergereturn/route/'
export const SPLIT_ROUTE = '/api/split/route/'
export const SPLIT_ROUTE_RETURN = '/api/splitreturn/route/'
export const REOPTIMIZED_ROUTE = '/api/reoptimized/route/'
export const REOPTIMIZED_ROUTE_RETURN = '/api/reoptimizedreturn/route/'
export const CREATE_ROUTE_BOROUGH = '/api/createRoute/borro/'
export const CREATE_ROUTE_RETURN_BOROUGH = '/api/createRoutereturn/borro/'
export const ORDER_FILE_UPLOAD = '/orders-upload/'
export const UPLOAD_FILE = "/ship/upload_file/";
export const USER_CHAT_ROOMS = "/chat/get_user_chat_rooms/";
export const GET_USERS_LIST = "/chat/get_users/"
export const GET_MESSAGES = "/chat/get_messages/"
export const GET_MESSAGES_NOTIFICATIONS = "/chat/get_notification_count/"
export const SEND_VONAGE_TEST_SMS = "/ship/vonagesms"
export const SEND_TWILIO_SMS = "/ship/twiliosms"
export const WEB_SIGN = "/ship/api/signature/";
export const AGENTS = "/ship/api/agents/";
export const VOCIE_CONNECT = "/ship/twilio/voice/";
export const CHECK_ROUTE_TASK_STATUS = '/api/task_status/'
export const UPDATE_ROUTE_PHARMACY = 'api/routewebpharmacy'


// export const GET_SALARY_PDF = "/salary/pdf";
// export const LUNCH = "/lunch";
export const FORGOT_PASSWORD = "/accounts/forgot-password/";
export const RESET_PASSWORD = "/accounts/reset-password/";
// export const FORGOT_PASSWORD_TOKEN = "employee/password-reset-check-token";
// export const PASSWORD_RESET_COMPLETE = "employee/password-reset-complete/";
// export const UPDATE_PASSWORD = "employee/update-password";
// export const DASHBOARD = "dashboard/";
// export const DELETE_NOTES = "dashboard/delete";
// export const UPDATE_NOTES = "dashboard/update";
// export const REFRESH_TOKEN = "employee/token-refresh/";
// export const DELETE_EXTRA_HOURS = "attendance/extra-time/delete";
