/**
 * Decodes a JWT token and returns its payload.
 * @param {string} token - The JWT token.
 * @returns {object|null} - The decoded payload or null if decoding fails.
 */
export const decodeJWT = (token) => {
    try {
        const payload = token.split('.')[1];
        // Add padding if necessary
        const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
        const padded = base64.padEnd(base64.length + (4 - (base64.length % 4)) % 4, '=');
        const decodedPayload = atob(padded);
        return JSON.parse(decodedPayload);
    } catch (error) {
        console.error('Failed to decode JWT:', error);
        return null;
    }
};
