import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import InputMask from "react-input-mask";
import { useReactToPrint } from "react-to-print";
import WestIcon from "@mui/icons-material/West";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import moment from "moment";

import { Avatar, Paper } from "@material-ui/core";
import { BiCheckDouble } from "react-icons/bi";

import Printdoc from "../../CommonComponents/PrintDoc/PrintDoc";
import PrintDocDown from "../../CommonComponents/PrintDoc/PrintDocDown";
import jsPDF from "jspdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { CREATE_ORDER, ALL_PHARMACY_LIST, WEB_SIGN, ORDER_COMMUNICATION } from "../../../utils/apiUrls";
import { getApiWithAuth, postApiWithAuth } from "../../../utils/api";

import { landline, mobile, phone, route_report } from "../../../assests";

import "./detailOrder.css";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button
} from "@mui/material";
import ShowPDF from "../../CommonComponents/ShowPDF/ShowPDF";
import { Link } from "react-router-dom";
import { color } from "chart.js/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatContainer: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    background: "#E2E2E2",
    display: "flex",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

function DetailOrder({ orderid, handleViewModalClose }) {
  const { pid, oid } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const createDate = new Date();

  const categorizeImagesByAttempt = (images) => {
    const categorized = {};

    images.forEach((image) => {
      const key = image.attempt || "null";
      if (!categorized[key]) {
        categorized[key] = [];
      }
      categorized[key].push(image);
    });

    return categorized;
  };

  useEffect(() => {
    const pharmacyId = localStorage.getItem("pharmacyId");
    if (pid !== undefined) {
      if (parseInt(pid) !== parseInt(pharmacyId)) {
        navigate("/404");
      }
    }
  }, [pid]);

  const [orderDetail, setOrderDetail] = useState({
    createdAt: "",
    deliveryDate: new Date(),
    timeWindow: "Anytime",
    name: "",
    language: "english",
    address: "",
    apart: "",
    phoneNumber: "",
    cellNumber: "",
    workNumber: "",
    zip: "",
    note: "",
    location_image: "",
    totalCopay: 0,
    signRequired: false,
    reqPhotoId: false,
    registed: false,
    collectCopay: false,
    hippaForm: false,
    lineForm: false,
    nfForm: false,
    bussAddress: false,
    parcelPlace: "Face to face",
    lat: "",
    lng: "",
    status: "",
    deliverAt: "",
    driver: "",
    signature_img: "",
    parcel_recieved_by_image: "",
    parcel_recieved_by_images: "",
    delivery_imgs: [],
    orderticket: {},
    slug: "",
    requestCouncelling: false,
    pharmacyId: "",
    order_file: "",
    rxList: [
      {
        id: "",
        copay: "",
        rxnumber: "",
        date: "",
        masterid: "",
        select: false,
      },
    ],
  });
  const [streetViewUrl, setStreetViewUrl] = useState("");
  const [communicationData, setCommunicationData] = useState([]);
  const [eSignDetails, setESignDetails] = useState({});
  const [selectedNumber, setSelectedNumber] = useState("");
  const [mapError, setMapError] = useState(false);
  const [pharmacyDetail, setPharmacyDetail] = useState({});
  const [activeTab, setActiveTab] = useState("Upload slip");
  const [selectedImage, setSelectedImage] = useState(null);
  const [numberSeleteModal, setNumberSeleteModal] = useState(false);
  const userType = localStorage.getItem("userType");

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeFullscreen = () => {
    setSelectedImage(null);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const getPharmacyDetail = async (id) => {
    let response;
    if (pid !== undefined) {
      response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${pid}`);
    } else {
      response = await getApiWithAuth(ALL_PHARMACY_LIST + `/${id}`);
    }
    if (response.data !== undefined) {
      setPharmacyDetail(response.data.data);
    } else {
      console.log(response.status);
    }
  };

  const getSignDetails = async (id) => {
    const response = await getApiWithAuth(WEB_SIGN + `get-by-order/${id}/`);
    console.log(response)
    if (response.status !== 404) {
      setESignDetails(response.data.data);
    } else {
      console.log(response.status);
    }
  };

  const getOrderCommunication = async () => {
    try {
      const response = await getApiWithAuth(`${ORDER_COMMUNICATION}${oid}/`);
      console.log(response.data)
      if (response.data) {
        setCommunicationData(response.data.data);
      } else {
        console.log('No data found for this order.');
      }
    } catch (error) {
      console.error('Error fetching order communication data:', error);
    }
  };


  const sendAgainSMS = async () => {
    try {
      const data = {
        order_id: oid,
        phone_number: selectedNumber,
      };

      const response = await postApiWithAuth(WEB_SIGN + 'send-again-sms/', data);

      if (response.data.status === 200) {
        // Handle success, e.g., show a success message
        console.log('SMS sent successfully');
        toast.success("SMS sent successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // You might want to update state or show a notification to the user
      } else {
        toast.error('Backend Error!', {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error('Backend Error!', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setNumberSeleteModal(false);
    setSelectedNumber("");
    getSignDetails(oid);
  };

  useEffect(() => {
    if (orderDetail.status === "5") {
      setActiveTab("Signed slip");
    }
  }, [orderDetail.status]);
  // useEffect(() => {
  //   const streetViewUrlApi = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${orderDetail.lat},${orderDetail.lng}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
  //   setStreetViewUrl(streetViewUrlApi);
  // }, [orderDetail.address, orderDetail.lat, orderDetail.lng]);

  // useEffect(() => {
  //   if (orderDetail.address !== "") {
  //     const fetchLocation = async () => {
  //       try {
  //         const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
  //           orderDetail.address
  //         )}&key=AIzaSyDWHHyyp0MS94zSt5ONEHeazFnLUDfRrmQ`;
  //         const response = await axios.get(geocodingUrl);
  //         const { results } = response.data;
  //         if (results.length > 0) {
  //           const { lat, lng } = results[0].geometry.location;
  //           setOrderDetail({
  //             ...orderDetail, // Spread the existing state
  //             lat: lat, // Update the lat property with the new value
  //             lng: lng, // Update the lng property with the new value
  //           });
  //           setMapError(false);
  //         } else {
  //           setMapError(true);
  //           setOrderDetail({
  //             ...orderDetail, // Spread the existing state
  //             lat: "", // Update the lat property with the new value
  //             lng: "", // Update the lng property with the new value
  //           });
  //         }
  //       } catch (error) {
  //         console.log("Google map give error yet please try again later!");
  //       }
  //     };

  //     fetchLocation();
  //   }
  // }, [orderDetail.address, orderDetail.lat, orderDetail.lng]);

  useEffect(() => {
    if (oid === undefined) {
      getDetailOrder(orderid);
    } else {
      getDetailOrder(oid);
    }
  }, [oid, orderid]);

  console.log(
    "parcel_recieved_by_image",
    typeof orderDetail.parcel_recieved_by_image,
    orderDetail.parcel_recieved_by_image
  );
  const getDetailOrder = async (id) => {
    try {
      const response = await getApiWithAuth(
        CREATE_ORDER + `/${oid !== undefined ? oid : orderid}`
      );
      // dispatch(getPdfData(formData));
      console.log(response.data.data);
      console.log(response.data.data.orderticket);
      setOrderDetail({
        name: response.data.data?.name || "",
        address: response.data.data?.address || "",
        phoneNumber: response.data.data?.contact || "",
        cellNumber: response.data.data?.cell || "",
        workNumber: response.data.data?.workNumber || "",
        zip: response.data.data?.zip || "",
        timeWindow: response.data.data?.deliverydate,
        parcelPlace: response.data.data?.parcelPlace,
        signRequired: response.data.data?.signature,
        reqPhotoId: response.data.data?.photoid,
        registed: response.data.data?.refrigrated,
        collectCopay: response.data.data?.collectcopay,
        hippaForm: response.data.data?.hippaform,
        lineForm: response.data.data?.lineform,
        nfForm: response.data.data?.nfaqbform,
        bussAddress: response.data.data?.addresstype,
        deliveryDate: response.data.data?.deliverAt,
        createdAt: response.data.data?.createdAt,
        totalCopay: response.data.data?.totalcopay,
        language: response.data.data?.language,
        apart: response.data.data?.apart,
        note: response.data.data?.notes,
        lat: response.data.data?.latitude,
        lng: response.data.data?.longitude,
        lng: response.data.data?.longitude,
        status: response.data.data?.status,
        slug: response.data.data?.slug,
        location_image: response.data.data?.location_image,
        requestCouncelling: response.data.data?.requestCouncelling,
        signature_img:
          response.data.data?.ordermaster_route4me !== null
            ? response.data.data?.ordermaster_route4me[
              response.data.data?.ordermaster_route4me?.length - 1
            ]?.signature_image
            : undefined,
        parcel_recieved_by_image:
          response.data.data?.ordermaster_route4me?.find(
            (item) => item.parcel_recieved_by_image
          )?.parcel_recieved_by_image,
        parcel_recieved_by_images:
          response.data.data?.route_pharmacy_details?.find(
            (item) => item.parcel_recieved_by_image
          )?.parcel_recieved_by_image,
        delivery_imgs: response.data.data?.ordermaster_delivery,
        orderticket:
          response.data.data?.orderticket !== null
            ? response.data.data?.orderticket
            : undefined,
        pharmacyId: response.data.data?.pharmacy?.id,
        order_file: response.data.data?.order_file,
        driver: response.data.data?.driver?.name,
        deliverAt: response.data.data?.deliverAt,
        active: response.data.data?.isActive,
        rxList: response.data.data?.orderdetail || [], // Store 'text' data in rxList
      });
      getSignDetails(response.data.data.id);
      getPharmacyDetail(response.data.data?.pharmacy?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const categorizedImages = categorizeImagesByAttempt(
    orderDetail?.delivery_imgs
  );

  const componentRef2 = useRef();
  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (communicationData) {
      scrollToBottom();
    }
  }, [communicationData]);

  const handlePrintSlip = useReactToPrint({
    content: () => componentRef2.current,
  });

  const downloadUploadSlip = () => {
    window.open(`${orderDetail.order_file}`, "_blank");
  };

  const handleNumberSeleteOpen = () => {
    setNumberSeleteModal(true);
  };

  const handleNumberSeleteClose = () => {
    setNumberSeleteModal(false);
    setSelectedNumber("");
  };

  return (
    <div className="addOrderMain">
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Link
            to={
              pid !== undefined
                ? `/pharmacy/${pid}/list/order/Status_Id/5`
                : `/list/order/Status_Id/5`
            }
          >
            <WestIcon className="mt-sm" />
          </Link>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="addOrder-top">
              <div className="topFields">
                <p className="topFieldLabel">Created Date</p>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-top-field"
                  name="name"
                  placeholder="Created Date"
                  value={moment(orderDetail.createdAt).format("MM/DD/YYYY")}
                  readOnly
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Delivery Date</p>
                {/* <input
                  autoComplete="off"
                  type="date"
                  className="order-top-field"
                  name="deliveryDate"
                  placeholder="Delivery Date"
                  value={orderDetail.deliveryDate}
                  readOnly
                /> */}
                <DatePicker
                  className="order-top-field"
                  selected={new Date(orderDetail.deliveryDate)}
                  readOnly
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="topFields">
                <p className="topFieldLabel">Time Window</p>
                <select
                  name="timeWindow"
                  className="order-top-field"
                  id="timeWindow"
                  value={orderDetail.timeWindow}
                  readOnly
                >
                  <option value="Anytime">Anytime</option>
                  <option value="9am-3pm">9am-3pm</option>
                  <option value="2pm-6pm">2pm-6pm</option>
                  <option value="5pm-9pm">5pm-9pm</option>
                  <option value="Urgent">Urgent</option>
                </select>
              </div>
              {/* <div className="topFields">
                <select
                  className="order-top-field"
                  name="language"
                  id="language"
                  value={orderDetail.language}
                  readOnly
                >
                  <option value="">
                    <em>Select Language</em>
                  </option>
                  <option value="english">English</option>
                  <option value="french">French</option>
                  <option value="spanish">Spanish</option>
                </select>
              </div> */}
            </div>
          </div>
          <form>
            <div className="orderBasicDiv">
              <div className="orderBasicInfo">
                <h2>To</h2>
                <div className="orderMainDiv">
                  <i className="fa fa-user detail-in-icon" />
                  <h3 className="orderMainText">{orderDetail.name}</h3>
                </div>
                <div className="orderMainDiv">
                  <i className="fa fa-home detail-in-icon" />
                  <p className="orderMainText">{orderDetail.address}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={phone} alt="Logo" />
                  <p className="orderMainText">{orderDetail.phoneNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={mobile} alt="Logo" />
                  <p className="orderMainText">{orderDetail.cellNumber}</p>
                </div>
                <div className="orderMainDiv">
                  <img className="detail-in-icon" src={landline} alt="Logo" />
                  <p className="orderMainText">{orderDetail.workNumber}</p>
                </div>
              </div>
              <div className="locImgDiv">
                <h1>Location View</h1>
                {orderDetail.lat && orderDetail.lng && (
                  <img
                    src={orderDetail.location_image}
                    alt="Google Maps Street View"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
                {/* <img className="locatioImg" src={locationImg} alt="location img" /> */}
              </div>
            </div>

            <Grid container className="order-form-grid" spacing={2}>
              {/* First Row */}
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Name</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="name"
                  placeholder="Name"
                  value={orderDetail.name}
                  readOnly
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Phone Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={orderDetail.phoneNumber}
                  readOnly
                >
                  {() => (
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      readOnly
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Cell Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={orderDetail.cellNumber}
                  readOnly
                >
                  {() => (
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="cellNumber"
                      placeholder="Cell Number"
                      readOnly
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Work Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={orderDetail.workNumber}
                  readOnly
                >
                  {() => (
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="workNumber"
                      placeholder="Work Number"
                      readOnly
                    />
                  )}
                </InputMask>
              </Grid>
              {/* Second Row */}
              <Grid item className="order-grid" xs={6}>
                <label className="order-field-label">Address</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="address"
                  placeholder="Address"
                  value={orderDetail.address}
                  readOnly
                />
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Zip Code</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="zip"
                  placeholder="Zip Code"
                  value={orderDetail.zip}
                  disabled
                />
                {orderDetail.zip === "" && (
                  <p style={{ color: "red" }}>ZipCode is mandatory!</p>
                )}
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <label className="order-field-label">Apt Number</label>
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="apart"
                  placeholder="Apt Number"
                  value={orderDetail.apart}
                />
              </Grid>
            </Grid>

            <div className="textLine">
              <p className="textLine-p">
                <span style={{ fontWeight: "700" }}>Please Confirm</span> with
                the patient for apartment number if they don’t live in a private
                house{" "}
              </p>
              <div className="textCheckDiv">
                <p>Business Address</p>
                <Checkbox
                  name="bussAddress"
                  checked={orderDetail.bussAddress}
                  readOnly
                />
              </div>
            </div>

            <div className="rxDetail">
              <h2>Details</h2>
              <Grid container className="order-form-grid" spacing={2}>
                {/* First Row */}
                <Grid item className="order-grid" xs={1.5}>
                  <h3>Count</h3>
                </Grid>
                <Grid item className="order-grid" xs={4}>
                  <h3>RX Number</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Date</h3>
                </Grid>
                <Grid item className="order-grid" xs={3}>
                  <h3>Copay</h3>
                </Grid>
              </Grid>
              {orderDetail.rxList.map((rx, i) => (
                <Grid container className="order-form-grid" spacing={2}>
                  {/* First Row */}
                  <Grid item className="order-grid" xs={1.5}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name="i"
                      style={{ textAlign: "center" }}
                      placeholder="Count"
                      value={i + 1}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-grid" xs={4}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.rx_number`}
                      placeholder="RX Number"
                      value={rx.rxnumber}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-grid" xs={3}>
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.date`}
                      placeholder="Date Filled"
                      value={rx.date}
                      readOnly
                    />
                  </Grid>
                  <Grid
                    item
                    className="order-grid"
                    style={{ display: "flex", alignItems: "center" }}
                    xs={3}
                  >
                    <input
                      autoComplete="off"
                      type="text"
                      className="order-field"
                      name={`rxList.${i}.copay`}
                      placeholder="Copay"
                      value={rx.copay}
                      readOnly
                    />
                    <Checkbox
                      name={`rxList.${i}.select`}
                      checked={rx.select}
                      readOnly
                    />
                  </Grid>
                </Grid>
              ))}
            </div>

            <Grid container className="order-form-grid" spacing={2}>
              <Grid item className="order-grid" xs={6}>
                <h2>Delivery Note</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid" xs={12}>
                    <textarea
                      rows="9"
                      className="order-textarea-field"
                      name="note"
                      placeholder="Write any indications / missing information for delivery driver and facility"
                      value={orderDetail.note}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Actions</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#000000",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      Signature Required
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="signRequired"
                      checked={orderDetail.signRequired}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#FF950F",
                        color: "#FF950F",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      Required Photo ID
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="reqPhotoId"
                      checked={orderDetail.reqPhotoId}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#0094FF",
                        color: "#0094FF",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      Refrigerated
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="registed"
                      checked={orderDetail.registed}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#05B321",
                        color: "#05B321",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      Copay ${orderDetail.totalCopay.toFixed(2)}
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="collectCopay"
                      checked={orderDetail.collectCopay}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <select
                      name="parcelPlace"
                      id="parcelPlace"
                      style={{
                        borderColor: "#000000",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 600,
                        backgroundColor: "#fff",
                      }}
                      className="order-field"
                      value={orderDetail.parcelPlace}
                      readOnly
                    >
                      <option value="">
                        <em>Select Parcel Place</em>
                      </option>
                      <option value="Face to face">FACE TO FACE</option>
                      <option value="Leave with receptionist">
                        LEAVE WITH RECEPTIONIST
                      </option>
                      <option value="Leave at door if pt e-signs">
                        LEAVE AT DOOR IF PT E-SIGNS
                      </option>
                      <option value="Leave at door">LEAVE AT DOOR</option>
                      <option value="Leave in the mailbox">
                        LEAVE IN THE MAILBOX
                      </option>
                      <option value="Leave in mail box if pt e-signs">
                        LEAVE IN MAIL BOX IF PT E-SIGNS
                      </option>
                    </select>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="order-grid" xs={3}>
                <h2>Get Forms Signed</h2>
                <Grid container className="order-form-grid" spacing={2}>
                  {/* Four Fields in a 3-3-3-3 Configuration */}
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#000000",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      Hipaa Form
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="hippaForm"
                      checked={orderDetail.hippaForm}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#000000",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      Lien Form
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="lineForm"
                      checked={orderDetail.lineForm}
                      readOnly
                    />
                  </Grid>
                  <Grid item className="order-in-grid-checks" xs={12}>
                    <p
                      style={{
                        borderColor: "#000000",
                        color: "#000000",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      className="order-field"
                    >
                      NF AOB Form
                    </p>
                    <Checkbox
                      style={{ padding: "0px" }}
                      name="nfForm"
                      checked={orderDetail.nfForm}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className="order-btn">
              {orderid !== undefined ? (
                <button
                  type="button"
                  className="del-order"
                  onClick={handleViewModalClose}
                >
                  Close
                </button>
              ) : (
                <button
                  type="button"
                  className="del-order"
                  onClick={() =>
                    pid !== undefined
                      ? navigate(`/pharmacy/${pid}/list/order`)
                      : navigate("/list/order")
                  }
                >
                  Back
                </button>
              )}
              {/* <button type="submit" className="save-order">Save & Print <PrintIcon style={{color: '#0455A6', marginLeft: '10px'}} /></button> */}
            </div>
          </form>
        </Grid>
        <Grid item xs={5}>
          <div className="actiontabsmain">
            <div className="actiontabs">
              <p
                className={`actiontabButtons ${activeTab === "Upload slip" ? "activetab" : ""
                  }`}
                onClick={() => handleTabClick("Upload slip")}
              >
                Upload slip
              </p>
              <p
                className={`actiontabButtons ${activeTab === "Signed slip" ? "activetab" : ""
                  }`}
                onClick={() => handleTabClick("Signed slip")}
              >
                Signed slip
              </p>
              <p
                className={`actiontabButtons ${activeTab === "Delivery Proof" ? "activetab" : ""
                  }`}
                onClick={() => handleTabClick("Delivery Proof")}
              >
                Delivery Proof
              </p>
              <p
                // className={
                //   orderDetail.orderticket !== undefined
                //     ? `actiontabButtons ${activeTab === "Conversation" ? "activetab" : ""
                //     }`
                //     : "actiontabButtons disable"
                // }
                className={`actiontabButtons ${activeTab === "Conversation" ? "activetab" : ""}`}
                onClick={() => {
                  handleTabClick("Conversation");
                  getOrderCommunication();
                }}
              >
                Conversation
              </p>
            </div>

            {activeTab === "Signed slip" ? (
              <div style={{ display: "flex", justifyContent: "space-between", }}>
                {(
                  (!orderDetail?.signature_img && (userType === "Admin" || userType === "Staff" || userType === "SubAdmin")) ||

                  (!orderDetail?.signature_img && (userType !== "Admin" && userType !== "Staff" && userType !== "SubAdmin") && orderDetail?.status === "5")
                ) && (
                    // Button to send E-Sign SMS
                    <button
                      type="button"
                      className="e-sign-btn"
                      style={{ marginRight: "10px" }}
                      onClick={handleNumberSeleteOpen}
                    >
                      Send E-Sign SMS
                    </button>
                  )}
                <img
                  src={route_report}
                  onClick={() => {
                    if (pharmacyDetail.pos_types === "PrimeRX") {
                      handlePrintSlip();
                    } else if (
                      pharmacyDetail.pos_types === "BestRx" ||
                      pharmacyDetail.pos_types === "PioneerRx"
                    ) {
                      if (orderDetail.status === "5") {
                        window.open(
                          `https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderDetail.slug}_signed.pdf?embedded=true`,
                          "_blank"
                        );
                      } else {
                        window.open(orderDetail.order_file, "_blank");
                      }
                    }
                  }}
                  alt="print"
                />
              </div>
            ) : (
              activeTab === "Upload slip" && (
                <img
                  src={route_report}
                  onClick={() => {
                    downloadUploadSlip();
                  }}
                  alt="print"
                />
              )
            )}
          </div>

          {activeTab === "Upload slip" && (
            <div style={{ display: "contents" }}>
              <iframe
                src={`${orderDetail.order_file}#toolbar=0&navpanes=0&scrollbar=0`}
                width="100%"
                height="100%"
                style={{ marginTop: 10 }}
              />
            </div>
          )}

          {activeTab === "Signed slip" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              {pharmacyDetail.pos_types === "PrimeRX" ? (
                <Printdoc
                  orderDetail={orderDetail}
                  edit={true}
                  upload={false}
                  view={false}
                  pharmacy={pharmacyDetail}
                />
              ) : pharmacyDetail.pos_types === "BestRx" ||
                pharmacyDetail.pos_types === "PioneerRx" ? (
                <div style={{ flex: 1 }}>
                  {orderDetail.status === "5" ? (
                    <iframe
                      src={`https://pilldrop-s3-bucket.s3.amazonaws.com/images/sign_file/order_${orderDetail.slug}_signed.pdf?embedded=true#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                      style={{ border: "none", marginTop: 10 }}
                    />
                  ) : (
                    <iframe
                      src={`${orderDetail.order_file}#toolbar=0&navpanes=0&scrollbar=0`}
                      width="100%"
                      height="100%"
                      style={{ border: "none" }}
                    />
                  )}
                </div>
              ) : null}
            </div>
          )}

          {activeTab === "Delivery Proof" &&
            (() => {
              // Decode the URL if it's double-encoded
              let parcel_recieved_by_image =
                orderDetail?.parcel_recieved_by_image;
              if (
                parcel_recieved_by_image &&
                parcel_recieved_by_image.startsWith(
                  "https://pilldrop-s3-bucket.s3.amazonaws.com/https%3A"
                )
              ) {
                parcel_recieved_by_image = decodeURIComponent(
                  parcel_recieved_by_image.replace(
                    "https://pilldrop-s3-bucket.s3.amazonaws.com/",
                    ""
                  )
                );
                console.log(
                  "decoded parcel_recieved_by_image",
                  parcel_recieved_by_image
                );
              }
              // Decode the URL if it's double-encoded
              let parcel_recieved_by_images =
                orderDetail?.parcel_recieved_by_images;
              console.log(
                "Original parcel_recieved_by_images:",
                parcel_recieved_by_images
              );

              if (
                parcel_recieved_by_images &&
                parcel_recieved_by_images.startsWith(
                  "https://pilldrop-s3-bucket.s3.amazonaws.com/https%3A"
                )
              ) {
                // Remove the duplicated part and then decode the rest of the URL
                parcel_recieved_by_images = parcel_recieved_by_images.replace(
                  "https://pilldrop-s3-bucket.s3.amazonaws.com/",
                  ""
                );
                console.log("Removed extra part:", parcel_recieved_by_images);

                // Decode the remaining URL to handle the %3A etc.
                parcel_recieved_by_images = decodeURIComponent(
                  parcel_recieved_by_images
                );
                console.log(
                  "Decoded parcel_recieved_by_images:",
                  parcel_recieved_by_images
                );
              }

              // Log the final cleaned URL to verify it matches other working S3 URLs
              console.log("Final image URL:", parcel_recieved_by_images);

              return (
                <>
                  {Object.keys(categorizedImages).map((attempt, index) => (
                    <div key={attempt}>
                      <h3 style={{ fontSize: "18px", fontWeight: 700 }}>
                        Attempt {attempt}
                      </h3>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {categorizedImages[attempt].map((image, index) => (
                          <div
                            key={index}
                            style={{
                              width: "50%",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleImageClick(image.delivery_proof_image)
                            }
                          >
                            <img
                              src={image.delivery_proof_image}
                              alt={`delivery_proof_${image.id}`}
                              style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                  {userType === "Admin" && parcel_recieved_by_image && (
                    <>
                      <h3 style={{ fontSize: "18px", fontWeight: 700 }}>
                        Parcel Received by
                      </h3>
                      <div
                        style={{
                          width: "50%",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImageClick(parcel_recieved_by_image)
                        }
                      >
                        <img
                          src={parcel_recieved_by_image}
                          alt="parcel_received"
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </>
                  )}
                  {userType === "Admin" && parcel_recieved_by_images && (
                    <>
                      <h3 style={{ fontSize: "18px", fontWeight: 700 }}>
                        Return Parcel Received by
                      </h3>
                      <div
                        style={{
                          width: "50%",
                          padding: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImageClick(parcel_recieved_by_images)
                        }
                      >
                        <img
                          src={parcel_recieved_by_images}
                          alt="parcel_received"
                          style={{
                            width: "100%",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </>
                  )}
                </>
              );
            })()}

          {/* {activeTab === "Conversation" && (
            <div className={`classes.root col-span-8`}>
              <div className="flex flex-col gap-md items-end w-full p-md">
                <div>
                  <div className="flex items-center gap-sm">
                    {orderDetail.orderticket !== undefined &&
                      orderDetail.orderticket.ticket_status === "Completed" ? (
                      <p
                        className="text-[16px] font-[700] text-center"
                        style={{ color: "red" }}
                      >
                        Ticket Closed
                      </p>
                    ) : (
                      <>
                        <p
                          className="text-[16px] font-[700] text-center"
                          style={{ color: "red" }}
                        >
                          Ticket Pending
                        </p>
                      </>
                    )}
                  </div>
                </div>

                <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
                  <Paper elevation={0} className={classes.chatContainer}>
                    {orderDetail.orderticket !== undefined &&
                      orderDetail.orderticket.ticketcomment_set !== undefined &&
                      orderDetail.orderticket.ticketcomment_set.length > 0 &&
                      orderDetail.orderticket.ticketcomment_set.map(
                        (message, index) => (
                          <div
                            key={index}
                            className={
                              message.sender === "user"
                                ? `classes.message flex gap-sm mb-sm`
                                : `classes.message flex gap-sm mb-sm flex-row-reverse`
                            }
                          >
                            <Avatar className={classes.avatar}>
                              {message.sender === "user" ? "U" : "R"}
                            </Avatar>

                            <div className={"flex flex-col w-full"}>
                              <div
                                className={
                                  message.sender === "user"
                                    ? "flex items-center justify-between"
                                    : "flex items-center justify-between flex-row-reverse"
                                }
                              >
                                <p
                                  className={
                                    message.sender === "user"
                                      ? "text-blue text-[20px] font-[700] text"
                                      : "text-text text-[20px] font-[700] text-end"
                                  }
                                >
                                  {message.comment_by.first_name}{" "}
                                  {message.comment_by.last_name}
                                </p>
                                <p
                                  className={
                                    message.sender === "user"
                                      ? "flex gap-sm text-blue text"
                                      : "flex gap-sm flex-row-reverse text-text text-end"
                                  }
                                >
                                  Read <BiCheckDouble color="#2757FF" />{" "}
                                  {moment(message.created_at).format(
                                    "MM-DD-YYYY"
                                  )}{" "}
                                  |{" "}
                                  {moment(message.created_at).format(
                                    "hh:mm:ss a"
                                  )}
                                </p>
                              </div>
                              <div
                                className={
                                  message.sender === "user"
                                    ? "bg-blue text-white p-sm rounded-sm h-[50px] flex items-center"
                                    : "bg-gray text-text p-sm rounded-sm flex items-center h-[50px]"
                                }
                              >
                                {message.comment}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </Paper>
                </div>
              </div>
            </div>
          )} */}

          {activeTab === "Conversation" && (
            <div className="conversation-container" ref={chatContainerRef}>
              {communicationData?.length > 0 ?
                communicationData?.map((item, index) => {
                  const isOutgoing = item.type === 'outgoing';
                  const isSms = item.isSms;

                  // Determine side and styling
                  const messageClass = isOutgoing ? 'message outgoing' : 'message incoming';
                  const avatarLetter = isOutgoing ? 'P' : (orderDetail?.name[0] ?? 'U');
                  const avatarName = isOutgoing ? 'PillDrop' : orderDetail?.name ?? 'Client';
                  const avatarClass = isOutgoing ? 'avatar pilldrop' : 'avatar client';

                  // Format date/time
                  const formattedDateTime = moment(item.created_at).format('MM-DD-YYYY | hh:mm A');

                  return (
                    <div className={messageClass} key={index}>
                      {!isOutgoing && (
                        <div className="avatar-container">
                          <div className={avatarClass}>{avatarLetter}</div>
                          <div className="avatar-name">{avatarName}</div>
                        </div>
                      )}
                      <div className="message-content">
                        {isSms ? (
                          <div className="sms-content">
                            <p>{item.body}</p>
                            <div className="message-time">{formattedDateTime}</div>
                          </div>
                        ) : (
                          <div className="call-content">
                            {item.status === 'completed' ? (
                              <>
                                {item.recording_file ? (
                                  <audio controls>
                                    <source src={item.recording_file} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                  </audio>
                                ) : (
                                  <p>No recording available.</p>
                                )}
                                {/* <p>Duration: {item.duration} seconds</p> */}
                              </>
                            ) : (
                              <>
                                <p>Status: {item.status}</p>
                                <div style={{display: 'flex', justifyContent:'space-between'}}>
                                <p>Duration: {item.duration} seconds</p>
                                  <p>
                                    {isOutgoing ? (
                                      <i className="icon outgoing-icon"></i>
                                    ) : (
                                      <i className="icon incoming-icon"></i>
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                            <div className="message-time">{formattedDateTime}</div>
                          </div>
                        )}
                      </div>
                      {isOutgoing && (
                        <div className="avatar-container">
                          <div className={avatarClass}>{avatarLetter}</div>
                          <div className="avatar-name">{avatarName}</div>
                        </div>
                      )}
                    </div>
                  );
                })
                : <p>No Data Found!!</p>
              }
            </div>
          )}

          <div style={{ display: "none" }}>
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                paddingTop: "10px",
              }}
              ref={componentRef2}
            >
              <Printdoc
                orderDetail={orderDetail}
                edit={true}
                upload={false}
                view={false}
                pharmacy={pharmacyDetail}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Ensure it's above everything else
          }}
          onClick={closeFullscreen}
        >
          <img
            src={selectedImage}
            alt="Fullscreen"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              width: "auto",
              height: "auto",
            }}
          />
          <span
            style={{
              position: "absolute",
              top: "10px",
              right: "20px",
              fontSize: "30px",
              color: "white",
              cursor: "pointer",
            }}
            onClick={closeFullscreen}
          >
            ×
          </span>
        </div>
      )}

      <Dialog
        open={numberSeleteModal}
        onClose={handleNumberSeleteClose}
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#D9D9D9",
            color: "#0455A6",
            fontWeight: "800",
            padding: 1,
          }}
        >
          Select number for send E-Sign SMS
          <p style={{ fontWeight: '400', fontSize: '14px', color: 'black', padding: 1 }}>Request sent To {eSignDetails?.sms_number} At {moment(eSignDetails.updated_at).format(
            "MM-DD-YYYY"
          )}{" "}
            |{" "}
            {moment(eSignDetails.updated_at).format(
              "hh:mm:ss a"
            )}</p>
        </DialogTitle>

        <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
          <div className="phone-container">
            <label className="order-field-label">Enter Phone Number</label>
            <InputMask
              mask="(999) 999-9999"
              value={selectedNumber}
              onChange={(e) => setSelectedNumber(e.target.value)}
            >
              {() => (
                <input
                  autoComplete="off"
                  type="text"
                  className="order-field"
                  name="selectedNumber"
                  placeholder="Phone Number"
                />
              )}
            </InputMask>
            {orderDetail?.phoneNumber && (
              <div onClick={() => setSelectedNumber(orderDetail?.phoneNumber)} className={`phone-entry ${selectedNumber === orderDetail?.phoneNumber ? 'selected' : ''}`}>
                <span className="phone-label">Phone:</span>
                <span className="phone-number">{orderDetail?.phoneNumber}</span>
              </div>
            )}
            {orderDetail?.cellNumber && (
              <div onClick={() => setSelectedNumber(orderDetail?.cellNumber)} className={`phone-entry ${selectedNumber === orderDetail?.cellNumber ? 'selected' : ''}`}>
                <span className="phone-label">Cell:</span>
                <span className="phone-number">{orderDetail?.cellNumber}</span>
              </div>
            )}
            {orderDetail?.workNumber && (
              <div onClick={() => setSelectedNumber(orderDetail?.workNumber)} className={`phone-entry ${selectedNumber === orderDetail?.workNumber ? 'selected' : ''}`}>
                <span className="phone-label">Work:</span>
                <span className="phone-number">{orderDetail?.workNumber}</span>
              </div>
            )}
          </div>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            gap={"15px"}
            marginLeft={23}
          >
            <Button
              onClick={sendAgainSMS}
              variant="outlined"
              color="inherit"
              disabled={selectedNumber === ""}
              sx={{ backgroundColor: "white" }}
            >
              Send SMS
            </Button>
            <Button
              onClick={handleNumberSeleteClose}
              variant="outlined"
              color="inherit"
              sx={{ backgroundColor: "white" }}
            >
              Close
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

    </div>
  );
}

export default DetailOrder;
