import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Box,
    Modal,
} from "@mui/material";
import CallSharpIcon from '@mui/icons-material/CallSharp';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import { getApiWithAuth, patchApiWithAuth, postApiWithAuth, putApiWithAuth } from '../../../utils/api';
import { AGENTS, CREATE_CALL_NOTE, CREATE_ORDER, ORDER_MISSED_CALLS, UNKNOWN_CALL_ORDER_SET } from '../../../utils/apiUrls';
import { toast } from "react-toastify";
import moment from 'moment';
import './CallList.css'
import { act } from 'react';
import VoiceComponent from '../TwilioCalls/TwilioCalls';
import { red } from '@material-ui/core/colors';
import { action3 } from '../../../assests';

const renderCallIcon = (call) => {
    if (call.type === "incoming") {
        return <CallReceivedIcon />;
    } else if (call.type === "outgoing") {
        return <CallMadeIcon />;
    }
    return <CallSharpIcon />;
};

const getCallStatusColor = (status) => {
    if (status === "completed") {
        return "green";  // Green for completed
    } else if (status === "no-answer") {
        return 'red';  // Red for no answer
    } else if (status === "busy") {
        return '#FFA500';  // Yellow for busy
    }
    return "inherit";  // Default color if no status matches
};


function formatDuration(durationInSeconds) {
    // Parse the string to an integer
    const totalSeconds = parseInt(durationInSeconds, 10);

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Pad with zeros if necessary
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    // Return the formatted time string
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}



function CallList() {
    const [loading, setLoading] = useState(null);
    const [callingOrder, setCallingOrder] = useState({});
    const [modalFacilityNoteOpen, setModalFacilityNoteOpen] = useState(false);

    const [activeTab, setActiveTab] = useState('missed');
    const [callId, setCallId] = useState('');
    const [staffNote, setStaffNote] = useState('');
    const [orderSlug, setOrderSlug] = useState('');
    const [orderCheck, setOrderCheck] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState('');
    const [calls, setCalls] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);

    const [hasMore, setHasMore] = useState(true);
    const [initiateCall, setInitiateCall] = useState(false);
    const [page, setPage] = useState(1);
    const callListRef = useRef();


    const handleModalFacilityNoteClose = () => {
        setModalFacilityNoteOpen(false);
        setStaffNote("");
        setCallId("");
    };

    const handleCreateFacilityNote = (call) => {
        setModalFacilityNoteOpen(true);
        setCallId(call.id);
        setStaffNote(call.staff_note);
    };

    const loadCalls = async (loadpage) => {
        if (loading) return; // Prevents multiple requests when already loading
        setLoading(true);
        try {
            const response = await getApiWithAuth(ORDER_MISSED_CALLS, { missed: activeTab === 'missed' ? 'true' : 'false', 'list': 'true', 'tab': activeTab, page: loadpage });
            const newCalls = response.data.data.results;
            // Append new calls to the existing list
            if (loadpage === 1) {
                setCalls(newCalls);
            } else {
                setCalls((prevCalls) => [...prevCalls, ...newCalls]);
            }
            setHasMore(response.data.next !== null);
            setLoading(false);
        } catch (error) {
            console.error('Error loading calls', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        setCalls([]);
        setPage(1);
        loadCalls(1);
    }, [activeTab]);

    const handleScroll = () => {
        if (callListRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = callListRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !loading) {
                // Reaching the bottom, load more data
                setPage((prevPage) => prevPage + 1);
                loadCalls(page + 1);
            }
        }
    };

    const makeCall = (call) => {
        setInitiateCall(true);
        setCallingOrder({
            "number": call.clientNumber,
            "id": call?.order?.id,
            "name": call?.order?.name,
        });
    };

    useEffect(() => {
        const currentRef = callListRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [callListRef, handleScroll]);

    const formatCallDetails = (call) => {
        const type = call.type === 'incoming' ? 'Incoming' : 'Outgoing';
        const formattedDate = moment(call.created_at).format('MMMM Do YYYY, h:mm:ss a');
        return `Client Number: ${call.clientNumber} | Agent: ${call.agent_name} | Order: ${call?.order?.slug} | ${type} | ${formattedDate}`;
    };

    const handleTab = (tab) => {
        setActiveTab(tab);
    }

    const handleSaveCallNote = async () => {
        const formData = new FormData();
        if (staffNote !== "") {
            formData.append("staff_note", staffNote);
            const response = await patchApiWithAuth(
                CREATE_CALL_NOTE + `${callId}/update/`,
                formData
            );
            if (response.data !== undefined) {
                console.log(response.data);
                setModalFacilityNoteOpen(false);
                setCallId("");
                setStaffNote("");
                setError("");
            } else {
                setModalFacilityNoteOpen(false);
                setCallId("");
                setStaffNote("");
                setError("");
            }
        } else {
            setError("Add note for save");
        }
    };

    const handleSaveUnknownCall = async () => {
        setIsSaving(true);
        try {
            const response = await patchApiWithAuth((UNKNOWN_CALL_ORDER_SET), {
                twilioCallingId: orderCheck,
                orderSlug: orderSlug,
            });
            toast.success("Order saved against unknwon call successfully!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setOrderCheck('');
            setIsSaving(false);
            setOrderSlug('');
            loadCalls(1);
        } catch (error) {
            toast.error("Failed to save the order. Please try again.", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setIsSaving(false);
            setIsModalOpen(false);
            loadCalls(1);
        }
    };

    const handlefetchOrderDetails = async () => {
        try {
            const response = await getApiWithAuth(CREATE_ORDER + `/${orderSlug}`)
            setOrderDetails(response.data.data);
            setIsModalOpen(true);
        } catch (error) {
            toast.error("Failed to fetch order details. Please check the Order Slug.", {
                position: "top-right",
                autoClose: 1000,
                theme: "colored",
            });
        }
    };

    const handleCancelSave = () => {
        setIsModalOpen(false);
        setOrderDetails(null);
    };


    return (
        <>
            {initiateCall && <VoiceComponent orderCall={callingOrder} setSelectedOrder={setCallingOrder} />}

            <div className="calls_tabs">
                <button disabled={loading} onClick={() => handleTab('all')} className={activeTab === 'all' ? 'active' : ''}>
                    All Calls
                </button>
                <button disabled={loading} onClick={() => handleTab('dailed')} className={activeTab === 'dailed' ? 'active' : ''}>
                    Dailed
                </button>
                <button disabled={loading} onClick={() => handleTab('received')} className={activeTab === 'received' ? 'active' : ''}>
                    Received
                </button>
                <button disabled={loading} onClick={() => handleTab('missed')} className={activeTab === 'missed' ? 'active' : ''}>
                    Missed Calls
                </button>
                <button disabled={loading} onClick={() => handleTab('unknown')} className={activeTab === 'unknown' ? 'active' : ''}>
                    Unknown Calls
                </button>
            </div>

            <div className="tab-content">
                {(activeTab !== 'missed' && activeTab !== 'unknown') && (
                    <div className="call-list-container" ref={callListRef}>
                        {calls?.length > 0 ? (
                            <ul className='call-list'>
                                {calls?.map((call, index) => (
                                    <li key={index} className="call-item">
                                        <div>
                                            <p><strong>{call?.order?.name ? call?.order?.name : "UnKnown"}</strong></p>
                                            <p>{formatCallDetails(call)}</p>
                                        </div>
                                        {(call.recording_file && call.recording_file !== null) && (
                                            <div className="audio-player">
                                                <audio controls>
                                                    <source src={call.recording_file} type="audio/mp3" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        )}
                                        <div>
                                            <p style={{ color: getCallStatusColor(call.status) }}>
                                                {call.duration ? formatDuration(call.duration) : "00:00:00"}
                                            </p>
                                            <div style={{ display: 'flex' }}>
                                                {renderCallIcon(call)}
                                                <CallSharpIcon style={{ cursor: "pointer" }} onClick={() => makeCall(call)} />
                                                <img src={action3} style={{ cursor: "pointer", height: "20px", width: "20px", marginLeft: '10px' }} onClick={() => handleCreateFacilityNote(call)} />
                                                {activeTab === 'received' && (orderCheck !== call.id ? (
                                                    <button onClick={() => setOrderCheck(call.id)} style={{marginLeft: '10px'}}>Set Order</button>
                                                ) : (
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <input
                                                            type="text"
                                                            value={orderSlug}
                                                            onChange={(e) => setOrderSlug(e.target.value)}
                                                            placeholder="Enter order slug"
                                                            style={{
                                                                padding: '5px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                width: '100px',
                                                            }}
                                                        />
                                                        <button
                                                            onClick={() => handlefetchOrderDetails()}
                                                            disabled={isSaving}
                                                            style={{
                                                                padding: '5px 10px',
                                                                backgroundColor: '#007bff',
                                                                color: '#fff',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No calls available.</p>
                        )}
                    </div>
                )}

                {activeTab === 'unknown' && (
                    <div className="call-list-container" ref={callListRef}>
                        {calls?.length > 0 ? (
                            <ul>
                                {calls?.map((call, index) => (
                                    <li key={index} className="call-item">
                                        <div>
                                            <p><strong>{call?.order?.name ? call?.order?.name : "UnKnown"}</strong> {call.missed && !call.attend && (
                                                <span style={{ color: red[500], fontWeight: 'bold' }}>(Return Call Needed)</span>
                                            )}</p>
                                            <p>{formatCallDetails(call)}</p>
                                        </div>
                                        <div>
                                            <p style={{ color: getCallStatusColor(call.status) }}>
                                                {call.duration ? formatDuration(call.duration) : "00:00:00"}
                                            </p>

                                            <div style={{ display: 'flex' }}>
                                                {orderCheck !== call.id ? (
                                                    <button onClick={() => setOrderCheck(call.id)}>Set Order</button>
                                                ) : (
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <input
                                                            type="text"
                                                            value={orderSlug}
                                                            onChange={(e) => setOrderSlug(e.target.value)}
                                                            placeholder="Enter order slug"
                                                            style={{
                                                                padding: '5px',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                width: '100px',
                                                            }}
                                                        />
                                                        <button
                                                            onClick={() => handlefetchOrderDetails()}
                                                            disabled={isSaving}
                                                            style={{
                                                                padding: '5px 10px',
                                                                backgroundColor: '#007bff',
                                                                color: '#fff',
                                                                border: 'none',
                                                                borderRadius: '4px',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No unknown calls available.</p>
                        )}
                    </div>
                )}

                {activeTab === 'missed' && (
                    <div className="call-list-container" ref={callListRef}>
                        {calls?.length > 0 ? (
                            <ul>
                                {calls?.map((call, index) => (
                                    <li key={index} className="call-item">
                                        <div>
                                            <p><strong>{call?.order?.name ? call?.order?.name : "UnKnown"}</strong> {call.missed && !call.attend && (
                                                <span style={{ color: red[500], fontWeight: 'bold' }}>(Return Call Needed)</span>
                                            )}</p>
                                            <p>{formatCallDetails(call)}</p>
                                        </div>
                                        <div>
                                            <p style={{ color: getCallStatusColor(call.status) }}>
                                                {call.duration ? formatDuration(call.duration) : "00:00:00"}
                                            </p>

                                            <div style={{ display: 'flex' }}>
                                                <PhoneMissedIcon style={{ color: getCallStatusColor(call.status) }} />
                                                <CallSharpIcon style={{ cursor: "pointer" }} onClick={() => makeCall(call)} />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No missed calls available.</p>
                        )}
                    </div>
                )}
            </div>
            <Dialog
                open={modalFacilityNoteOpen}
                onClose={handleModalFacilityNoteClose}
                maxWidth="xl"
            >
                <DialogTitle
                    sx={{
                        backgroundColor: "#D9D9D9",
                        color: "#0455A6",
                        fontWeight: "800",
                        padding: 1,
                    }}
                >
                    Staff Notes
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1 }}>
                    <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                    <textarea
                        type="text"
                        className="modal-field notes-field"
                        name="note"
                        autoFocus
                        placeholder="Write your note here!!!"
                        value={staffNote}
                        onChange={(e) => setStaffNote(e.target.value)}
                        rows={4}
                    />
                    <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        gap={"15px"}
                        marginLeft={23}
                    >
                        <Button
                            onClick={handleModalFacilityNoteClose}
                            variant="outlined"
                            color="inherit"
                            sx={{ backgroundColor: "white" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleSaveCallNote()}
                            variant="outlined"
                            color="inherit"
                            sx={{ backgroundColor: "white" }}
                        >
                            Save
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Modal
                open={isModalOpen}
                onClose={handleCancelSave}
                aria-labelledby="order-details-modal"
                aria-describedby="modal-to-confirm-order-details"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <h2 id="order-details-modal">Confirm Order Details</h2>
                    {orderDetails ? (
                        <div>
                            <p><strong>Name:</strong> {orderDetails?.name}</p>
                            <p><strong>Address:</strong> {orderDetails?.address}</p>
                            <p><strong>Order Slug:</strong> {orderDetails?.slug}</p>
                            <p><strong>Zipcode:</strong> {orderDetails?.zip}</p>
                        </div>
                    ) : (
                        <p>Loading order details...</p>
                    )}
                    <div style={{ marginTop: '20px', textAlign: 'right' }}>
                        <Button onClick={handleCancelSave} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleSaveUnknownCall} disabled={isSaving}>
                            {isSaving ? 'Saving...' : 'Confirm'}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default CallList;
