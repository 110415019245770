import React, { useState, useEffect, useRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useParams, useNavigate } from 'react-router';
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Paper,
  InputBase,
  IconButton,
  Divider,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
} from "@material-ui/core";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForwardIcon from "@mui/icons-material/Forward";
import CustomLoader from "../../CommonComponents/Loader/CustomLoader";
import { ArrowDropDownCircle, CheckBox } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from 'moment';
import { AttachFile, CloseRounded, Send } from "@mui/icons-material";
import SMSCard from "./SMSCard/SMSCard";
import { closeIconRed } from "../../../assests";
import { BiCheckDouble } from "react-icons/bi";
import { getApiWithAuth, postApiWithAuth, patchApiWithAuth, putApiWithAuth } from "../../../utils/api";
import { SEND_TWILIO_SMS, ALL_PHARMACY_LIST, TWILIO_SMS_LIST, ORDER_CHAT_LIST, TWILIO_UNREAD_SMS_COUNT } from "../../../utils/apiUrls";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatContainer: {
    flex: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
    maxHeight: "calc(100vh - 200px)",
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  inputContainer: {
    background: "#E2E2E2",
    display: "flex",
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

const TwilioSMSList = () => {
  const classes = useStyles();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [ticketOrders, setTicketOrders] = useState([]);
  const [smsId, setSMSId] = useState("");
  const [smsDetails, setSMSDetails] = useState();
  const [search, setSearch] = useState("");
  const [uniquePharmacy, setUniquePharmacy] = useState([]);
  const [pharmacy, setPharmacy] = useState("");
  const [timer, setTimer] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [text, setText] = useState("");
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
      endDate: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      key: "selection",
    },
  ]);

  const calendarRef = useRef(null);

  const chatContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (smsDetails) {
      scrollToBottom();
    }
  }, [smsDetails]);

  
  const fetchPharmacies = async () => {
    try {
      const response = await getApiWithAuth(ALL_PHARMACY_LIST, { 'justList': true });
      if (response.data !== undefined) {
        setUniquePharmacy(response.data.data.map((item) => ({ id: item.id, name: item.name })));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendTwilioSms();
    }
  };
  const handleTicketClick = (smsId) => {
    setSMSId(smsId);
    setSelectedTicketId(smsId);
    getTicketOrders({ needId: smsId });
    markReadOrderChat(smsId);
  };

  const handleSelect = (ranges) => {
    console.log("ranges inside the handleselect", ranges)
    if (ranges.selection.endDate) {
      setDateRange([ranges.selection]);
      setCalendarVisible(false);
    }
  };

  const toggleCalendar = () => {
    setCalendarVisible(!isCalendarVisible);
  };

  const getTicketOrders = async ({ needId = null } = {}) => {
    setLoading(true);
    try {
      const response = await getApiWithAuth(TWILIO_SMS_LIST);
      if (response.data && Array.isArray(response.data.data)) {
        const ticketData = response.data.data; // Now an array of { group_key, sms }
        setTicketOrders(ticketData);
        setLoading(false);

        // Logging for debugging
        console.log('Current smsId:', smsId, 'Need ID:', needId);

        if (smsId === "" && ticketData.length > 0) {
          const firstGroup = ticketData[0];
          setSMSId(firstGroup.group_key);
          setSelectedTicketId(firstGroup.group_key);
          setSMSDetails(firstGroup.sms);
        } else if (smsId !== "") {
          const currentGroup = ticketData.find(group => group.group_key === smsId);
          if (currentGroup && needId === smsId) {
            setSMSDetails(currentGroup.sms);
          }
        }
      } else {
        setLoading(false);
        console.warn("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching ticket orders:", error);
      setLoading(false);
    }
  };


  const fetchUnreadSmsCount = async () => {
    const response = await getApiWithAuth(TWILIO_UNREAD_SMS_COUNT)
    const data = response.data.data;
    setUnreadCounts(data.unread_counts);
  };

  useEffect(() => {
    // Initialize WebSocket connection
    const unreadSMSClient = new W3CWebSocket('wss://testadmin.pilldrop.ai/ws/unread_sms/');

    // Handle connection open
    unreadSMSClient.onopen = () => {
      console.log('WebSocket connection established for unread SMS counts');
    };

    // Handle incoming messages
    unreadSMSClient.onmessage = (e) => {
      try {
        const data = JSON.parse(e.data);
        if (data.type === 'unread_sms_counts') {
          const counts = data.data;
          const { total_unread_count, unread_counts } = counts;

          // Update the state with new counts
          // setTotalUnreadCount(total_unread_count);
          // setUnreadCountsByOrder(unread_counts);

          setUnreadCounts(unread_counts);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    // Handle connection close
    unreadSMSClient.onclose = () => {
      console.log('WebSocket connection closed for unread SMS counts');
      // Optionally, implement reconnection logic here
    };

    // Handle errors
    unreadSMSClient.onerror = (error) => {
      console.error('WebSocket error for unread SMS counts:', error);
    };

    // Cleanup function to close WebSocket connection when component unmounts
    return () => {
      unreadSMSClient.close();
    };
  }, []);

  const markReadOrderChat = async (smsId) => {
    await patchApiWithAuth(
      ORDER_CHAT_LIST + `update_by_order/?order_id=${smsId}`, { is_read: true }
    );
    fetchUnreadSmsCount();
  };

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    getTicketOrders();

  }, [pharmacy, search, timer, dateRange]);

  useEffect(() => {
    fetchPharmacies();
    fetchUnreadSmsCount();
  }, []);

  const [filterName, setFilterName] = React.useState([]);
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const inputValue = e.target.value;
      setSearch(inputValue);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFilterName(
      typeof value === 'string' ? value.split(',') : value,
    );

    const pharmacyIds = uniquePharmacy
      .filter(pharmacy => value.includes(pharmacy.name))
      .map(pharmacy => pharmacy.id);
    setPharmacy(pharmacyIds);
  };

  const handleSendTwilioSms = async () => {
    if (smsId !== "") {
      setLoading(true);
      const response = await postApiWithAuth(SEND_TWILIO_SMS, {
        to_number: smsDetails[smsDetails.length - 1]?.clientNumber,
        text: text,
        order_id: smsDetails[smsDetails.length - 1]?.order?.id
      });
      console.log(response.data);
      if (response.data.error === undefined) {
        toast.success(response.data.data.message, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setText("")
    } else {
      console.log("Add number to send sms");
    }

    setText("");
    getTicketOrders({ needId: smsId });
    setLoading(false);
  };

  return (
    <div className="OrderList">
      {/* {loading && <CustomLoader />} */}
      <div className="second-row">
        <div className="filter-container">
          <div className="search-container">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              // onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleSearchKeyDown}
              placeholder="Order ID, Name, Address, Phone, Rx"
              className="search-input"
              autoComplete="off"
            />
          </div>
          <div className="search-filter-container">
            <Select
              defaultValue="0"
              className="filter-input no-underline-select"
              style={{
                width: "300px",
                padding: "0",
                backgroundColor: "primary.lighter", // Adjust the background color as needed
                // border: "0px solid",
                // borderColor: "transparent",
              }}
              onChange={(e) => setTimer(e.target.value)}
              IconComponent={() => <ArrowDropDownCircle sx={{ color: 'black', width: 20 }} />}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null, // Remove default behavior of trying to find the anchor element
                style: {
                  marginTop: '10px', // Adjust the margin-top as needed
                },
              }}
            >
              <MenuItem className="dropdown-text" value="0" disabled>Select</MenuItem>
              <MenuItem className="dropdown-text" value="Pending">Pending</MenuItem>
              <MenuItem className="dropdown-text" value="Completed">Completed</MenuItem>
            </Select>
          </div>
          {userType === "Admin" && (
            <div className="search-filter-container">
              <FormControl className="filter-input">
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  className="no-underline-select"
                  IconComponent={(props) => <ArrowDropDownCircle {...props} sx={{ color: 'black', width: 20 }} />}
                  multiple
                  displayEmpty
                  sx={{
                    fontSize: '11px',
                    "& .MuiSvgIcon-root": {
                      color: 'black'
                    }
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null, // Remove default behavior of trying to find the anchor element
                    style: {
                      marginTop: '10px', // Adjust the margin-top as needed
                    },
                  }}
                  value={filterName}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <p style={{ fontSize: '14px', opacity: 1, fontFamily: "Saira Extra Condensed" }}>Filter Pharmacy</p>;
                    }
                    return <p style={{ fontSize: '14px', opacity: 1, fontFamily: "Saira Extra Condensed" }}>{selected.length > 1 ? `${selected.slice(0, 1)}  ...` : selected}</p>
                  }}
                >
                  {uniquePharmacy?.map((pharmacy, index) => (
                    <MenuItem
                      key={index}
                      value={pharmacy.name}
                      sx={{
                        fontSize: '13px',
                      }}
                    >
                      <Checkbox size="small" checked={filterName.indexOf(pharmacy.name) > -1} />
                      {pharmacy.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="date-input-container">
            <div type="text" className="date-input">
              <CalendarMonthIcon
                style={{ fontSize: "28px", cursor: "pointer" }}
                onClick={toggleCalendar}
              />
              {moment(dateRange[0].startDate).format("MM-DD-YYYY")}
              <ForwardIcon style={{ fontSize: "32px" }} />
              {dateRange[0].endDate
                ? moment(dateRange[0].endDate).format("MM-DD-YYYY")
                : ""}
            </div>
            {isCalendarVisible && (
              <div className="calendar-container">
                <DateRange
                  ranges={dateRange}
                  onChange={handleSelect}
                  retainEndDateOnFirstSelection={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="customer_support max-h-screen grid grid-cols-12">
        <div className="ticket col-span-4 max-h-screen min-h-[80vh] height-auto overflow-auto">
          <div className="flex flex-col gap-sm">
            {ticketOrders && ticketOrders.map((ticket, i) => (
              <a
                className={`ticket-link ${selectedTicketId === ticket.group_key ? 'ticket-selected' : ''}`} // Use ticket instead of ticket.id
                onClick={() => {
                  handleTicketClick(ticket.group_key);
                  setSMSDetails(ticket.sms);
                }}
                key={i}
              >
                <SMSCard sms={ticket.sms} unreadCounts={unreadCounts[ticket.group_key] || 0} />
              </a>
            ))}
          </div>
        </div>

        <div className={`${classes.root} col-span-8`}>
          <div className="flex flex-col gap-md items-end w-full p-md h-full">
          <div className="w-full flex flex-col justify-between max-h-screen min-h-[80vh] height-auto">
            <Paper elevation={0} className={classes.chatContainer} ref={chatContainerRef}>
              {smsDetails !== undefined && smsDetails.length > 0 && smsDetails.slice().reverse().map((message, index) => (
                <div
                  key={index}
                  className={
                    message.type === "incoming"
                      ? `${classes.message} flex gap-sm mb-sm`
                      : `${classes.message} flex gap-sm mb-sm flex-row-reverse`
                  }
                >
                  <Avatar className={classes.avatar}>
                    {message.type === "incoming" ? "U" : "A"}
                  </Avatar>

                  <div className={"flex flex-col w-full"}>
                    <div
                      className={
                        message.type === "incoming"
                          ? "flex items-center justify-between"
                          : "flex items-center justify-between flex-row-reverse"
                      }
                    >
                      <p
                        className={
                          message.type === "incoming"
                            ? "text-blue text-[20px] font-[700] text"
                            : "text-text text-[20px] font-[700] text-end"
                        }
                      >
                        {message.type === "incoming" ? `${message?.order?.name} | ${message?.order?.slug}` : `${message?.agent_name}`}
                      </p>
                      <p
                        className={
                          message.type === "incoming"
                            ? "flex gap-sm text-blue text"
                            : "flex gap-sm flex-row-reverse text-text text-end"
                        }
                      >
                        {/* Read <BiCheckDouble color="#2757FF" /> */}
                        {message.created_at.split("T")[0]} {message.created_at.split("T")[1].split(".")[0]}

                        {/* {moment(message.created_at).format('MM-DD-YYYY')} | {moment(message.created_at).format('hh:mm:ss a')} */}
                      </p>
                    </div>
                    <div
                      className={`${(message.type === "incoming")
                        ? "bg-gray text-text "
                        : (message.type === "outgoing")
                          ? "bg-blue text-white"
                          : ""
                        } p-sm rounded-sm h-[50px] flex items-center`}
                    >
                      {message.body}
                    </div>


                  </div>
                </div>
              ))}
            </Paper>

            <div className="relative rounded-md mx-auto w-full max-w-[800px] flex justify-center bg-[#E2E2E2]">
              <Paper
                elevation={0}
                className={`classes.inputContainer background bg-[#E2E2E2] items-start flex justify-between p-sm w-full`}
              >
                <InputBase
                  placeholder="Type a message..."
                  name="text"
                  value={text}
                  autoComplete="off"
                  onChange={(e) => setText(e.target.value)}
                  className={"classes.input bg-[#E2E2E2] w-[80%]"}
                  onKeyPress={handleKeyPress}
                />

                <div className="bg-[#E2E2E2]">
                  <IconButton
                    color="primary"
                    onClick={handleSendTwilioSms}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSendTwilioSms();
                      }
                    }}
                    disabled={isSubmitting}>
                    <Send />
                  </IconButton>
                </div>
              </Paper>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwilioSMSList;
